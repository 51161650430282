import {camelCase, get, isEmpty, isNull, reduce, split} from 'lodash';

/**
 * Returns Object containing Sitecore fields
 *
 * @param {*} props Sitecore Link Field JSON response
 *
 * @returns {object} Containing JSON for Sitecore fields
 */
export const extractFieldsFn = (props) =>
  get(props, 'rendering.fields');
/**
 * Returns the number of elements returned from array
 *
 * @param {*} props Sitecore # of array response
 * @param {*} cols Sitecore # of cols
 * @returns {string} Element width in columns (1/12)
 */
export const columnWidth = (props, cols) =>
  [1, 2, 3, 4].indexOf(props) > -1 ? 12 / parseInt(props) : cols;

/**
 * Function to read the cookies from the browser
 *
 * @param {string} key Key-Name of the cookie
 * @returns {string} AMC Logout url per environment
 */
export const getCookieValue = (key) => {
  if (typeof Element === 'undefined') { return; }
  let re = new RegExp(key + '=([^;]+)');
  let value = re.exec(document.cookie);
  return value !== null ? unescape(value[1]) : null;
};

/**
 * Function to create a JSON from Cookie value
 *
 * @param {string} cookieValues Key-Name of the cookie
 * @param {string} useCamelCase to convert the keys into camelCase
 * @returns {JSON} JSON with values
 */
export const getCookieJson = (cookieValues, useCamelCase = false) => {
  const cookieValue = getCookieValue(cookieValues);
  if (isNull(cookieValue)) { return null; }
  let utmsArray = split(cookieValue, '&');
  return reduce(utmsArray, (result, value, key) => {
    const utmSplit = value.split('=');
    useCamelCase
      ? result[camelCase(utmSplit[0])] = utmSplit[1]
      : result[utmSplit[0]] = utmSplit[1];
    return result;
  }, {});
};

/**
 * Returns Web content api URL
 *
 * @param {*} url relative Url
 * @param {*} env App environment
 *
 * @returns {string} relative or abosulte mm com url per environment
 */
 export const getMMDomainUrl = (url, env, useMMComHomeDomain = false, previewEnvUnavailable = false, caseSensitive = false) => {
  let returnUrl = url;
  if (!isEmpty(url))
    url = url.slice(0, 1) === '/' ? url : '/' + url;
  if (env === null || typeof env === 'undefined')
    returnUrl = url;
  else if (env === 'prod')
    returnUrl = useMMComHomeDomain ? 'https://www.massmutual.com' : 'https://worksite.massmutual.com';
  else if (env === 'preview')
    returnUrl = useMMComHomeDomain ? (previewEnvUnavailable ? 'https://www.massmutual.com' 
    : 'https://www2.massmutual.com') : (previewEnvUnavailable ? 'https://worksite.massmutual.com' 
    : 'https://worksite2.massmutual.com') ;
  else if (env === 'qa')
    returnUrl = useMMComHomeDomain ? 'https://www-qa.massmutual.com' : 'https://worksite-qa.massmutual.com';
  else
    returnUrl = useMMComHomeDomain ? 'https://www-dev.massmutual.com' : 'https://worksite-dev.massmutual.com';

  return caseSensitive ? returnUrl + url : (returnUrl + url).toLowerCase();
};

export const getAppDomain = (appEnv) => {
  if (appEnv === 'prod')
    return 'https://worksite.massmutual.com';
  else if (appEnv === 'preview')
    return 'https://worksite2.massmutual.com';
  else if (appEnv === 'qa') 
    return 'https://worksite-qa.massmutual.com';
  else
    return 'https://worksite-dev.massmutual.com'; 
};

